import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useLocation,
} from 'react-router-dom';
import DashboardPic from "../assets/img/dashboard-img.png";
import "../assets/css/mystyles.css";
import {
  Button,
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  Image,
  Wrap,
  WrapItem,
  useMediaQuery,
  Tooltip,
} from "@chakra-ui/react";

import { WarningIcon } from "@chakra-ui/icons";

function NotFound() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [listFormId, setListFormId] = useState([]);
  const [listAllForms, setListAllForms] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [countDown, setCountDown] = useState(-1);
  const [query, setQuery] = useState(null);
  const location = useLocation();

  useEffect(() => {
    document.title = "Where is the Form?";
  }, []);

  useEffect(() => {
    const fetchFormData = async () => {
      const customFunctions = "getAllFormsDev"

      const payload = {
        active: location.pathname === "/",
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api-key/orgs/d045RKCZp8qWv1M/projects/rQqa6x6rBFazqvD/functions/${customFunctions}/execute`,
        payload,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_APIKEY,
          },
        }
      );

      const fetchedFormList = data.result
      let tempListFormId = [];

      if (fetchedFormList.length > 0) {
        tempListFormId = fetchedFormList.map((item) => {
          return item.slugLink.toLowerCase();
        });
        setListFormId(tempListFormId);
      }
      setListAllForms(data.result)
    };

    fetchFormData();
  }, [location]);

  const goToSlug = () => {
    if (query) {
      let dataIndex = listFormId.indexOf(query.toLowerCase());
      if (dataIndex !== -1) {
        if (listAllForms[dataIndex].active === true) {
          setMessage("Form ditemukan, Anda akan diarahkan dalam ");
          setCountDown(3);
          setMessageType("success");
        } else {
          setMessage("Maaf, Form yang anda masukkan sudah tidak aktif");
          setMessageType("warning");
          setTimeout(() => {
            setMessage("");
          }, 3000);
        }
      } else {
        setMessage(
          "Form tidak ditemukan, mohon periksa kembali ID Form yang anda masukkan"
        );
        setMessageType("warning");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } else {
      setMessage("Silahkan masukkan ID Form terlebih dahulu");
      setMessageType("warning");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else if (countDown === 0) {
      setMessage("");
      window.location.href = "/" + query;
    }
  }, [countDown, query]);

  const enterHandler = (e) => {
    if (e.key === "Enter") {
      goToSlug();
    }
  };

  return (
    <>
      <Flex
        align="center"
        justify={{ base: "center", md: "space-around", xl: "space-between" }}
        direction={{ base: "column", md: "column" }}
        wrap="no-wrap"
        minH="100vh"
        background="white"
      >
        <Stack
          direction={{ base: "column", md: "row", lg: "row" }}
          minH="100vh"
        >
          <Stack
            w={{ base: "80vw", md: "40vw", lg: "30vw" }}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            align="center"
            justify="center"
            background="white"
          >
            <Box align="center" justify="center">
              <Image src={DashboardPic} alt="Not Found, Bung!" />
            </Box>
          </Stack>
          <Stack
            w={{ base: "100vw", md: "60vw", lg: "50vw" }}
            minH={{ base: "50vh", sm: "50vh" }}
            align="left"
            justify="center"
            background="white"
          >
            <Box
              width="100%"
              padding="12px 24px 24px 24px"
              display="flow"
              direction="row"
              overflow="hidden"
            >
              <Heading as="h1" size="xl" mb={4}>
                Where is the Form?
              </Heading>
              <Heading as="h2" size="md" mb={8}>
                Periksa dan masukkan kembali ID form yang benar untuk dapat
                mengisi form.
              </Heading>
              <Text fontSize="md" mb={4}>
                <b>Masukkan ID Form</b>
                <Tooltip
                  label="Bila link form p-forms.com/wardah-beauty, ID form adalah wardah-beauty "
                  placement="right"
                  fontSize="md"
                  variant="white"
                >
                  <WarningIcon
                    style={{
                      color: "rgba(10, 17, 45, 0.4)",
                      marginLeft: "16px",
                    }}
                  />
                </Tooltip>
              </Text>
              <Wrap spacing="1em" mb={4}>
                <WrapItem style={isMobile ? { width: "100%" } : null}>
                  <InputGroup size="md">
                    <InputLeftAddon
                      children={isMobile ? "p-forms..." : "p-forms.com/"}
                    />
                    <Input
                      placeholder="Form ID"
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => enterHandler(e)}
                    />
                  </InputGroup>
                </WrapItem>
                {isMobile && message !== "" ? (
                  <WrapItem>
                    <p
                      style={
                        messageType === "warning"
                          ? { display: "block", color: "#EB5757" }
                          : { display: "block", color: "#186704" }
                      }
                    >
                      {message} {countDown > 0 ? countDown : null}
                    </p>
                  </WrapItem>
                ) : null}
                <WrapItem style={isMobile ? { width: "100%" } : null}>
                  <Button
                    colorScheme="blue"
                    width="100%"
                    onClick={() => goToSlug()}
                  >
                    Go To Form
                  </Button>
                </WrapItem>
              </Wrap>

              {message !== "" && !isMobile ? (
                <p
                  style={
                    messageType === "warning"
                      ? { display: "block", color: "#EB5757" }
                      : { display: "block", color: "#186704" }
                  }
                >
                  {message} {countDown > 0 ? countDown : null}
                </p>
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
}

export default NotFound;
