import React, {useEffect, useState} from 'react';
import { camelCase, isEmpty } from "lodash";
import axios from 'axios';
import '../../assets/css/mystyles.css';
import {
    Text,
    Input,
    useMediaQuery,
    InputGroup,
    InputRightElement,
  } from "@chakra-ui/react"

import { Autocomplete } from "@material-ui/lab";
import { ChevronDownIcon } from "@chakra-ui/icons";

function LocationField ({
    isLocation,
    FieldsbyFormsResult,
    setFieldsbyFormsResult,
    index,
    params
}) {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedProvinceField, setSelectedProvinceField] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCityField, setSelectedCityField] = useState(null)
    const [listProvince, setListProvince] = useState([]);
    const [listCity, setListCity] = useState([]);
    const [listPostcode, setListPostcode] = useState([]);
    const [selectedPostcodeField, setSelectedPostcodeField] = useState(null)

    useEffect(() => {
        const fetchPostcode = async() => {
            try {
                const { data } = await axios.get(`https://jne-api.pti-cosmetics.com/jne_postcode?city_id=eq.${selectedCity}`)
                setListPostcode(data)
            } catch (error) {
                setListPostcode([])
            }
        }
        if (!isEmpty(selectedCity)) {
            fetchPostcode();
        }
    }, [selectedCity])

    useEffect(() => {
        const fetchCity = async() => {
            try {
                const { data } = await axios.get(`https://jne-api.pti-cosmetics.com/jne_cities?province_code=eq.${selectedProvince}`)
                setListCity(data)
            } catch (error) {
                setListCity([])
            }
        }

        if (!isEmpty(selectedProvince)) {
            fetchCity();
        }
    }, [selectedProvince])

    useEffect(() => {
        const fetchProvince = async() => {
            try {
                const { data } = await axios.get('https://jne-api.pti-cosmetics.com/jne_province')
                setListProvince(data);
            } catch (error) {
                setListProvince([])
            }
        }

        if (isLocation) {
            fetchProvince();
        }
    }, [isLocation])

    const locationChangeHandler = ({ index, fieldName, values, type }) => {
        var tempFieldsbyFormsResult = [...FieldsbyFormsResult];
        let objectKey = camelCase(`Split ${fieldName} ${type}`)
        let cityKey = camelCase(`Split ${fieldName} city`)
        let postcodeKey = camelCase(`Split ${fieldName} postcode`)
        let valuesKey
        if (type === 'province') {
            valuesKey = 'province_name'
            setSelectedProvince(values.province_code)
            setSelectedProvinceField(values)

            setSelectedCity(null)
            setSelectedCityField(null)
            setSelectedPostcodeField(null)
            tempFieldsbyFormsResult[index][cityKey] = null;
            tempFieldsbyFormsResult[index][postcodeKey] = null;
        } else if (type === 'city') {
            valuesKey = 'city'
            setSelectedCity(values.city)
            setSelectedCityField(values)
            setSelectedPostcodeField(null)
            tempFieldsbyFormsResult[index][postcodeKey] = null;
        } else if (type === 'postcode') {
            valuesKey = 'zip_code'
            setSelectedPostcodeField(values)

        }
        tempFieldsbyFormsResult[index][objectKey] = values[valuesKey]
        setFieldsbyFormsResult(tempFieldsbyFormsResult)
    }

    return(
        <>
            <Text mb={2} fontSize="md">
                Provinsi
            </Text>
            <Autocomplete
                value={selectedProvinceField}
                options={listProvince}
                style={{ width: `${isMobile ? '100%' : '50%'}` }}
                id="custom-input-demo"
                getOptionLabel={(option) => option.province_name}
                onChange={(_, values) =>
                locationChangeHandler({index, fieldName: params.slugField, values, type: "province"})
                }
                renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <InputGroup size="md">
                    <Input
                        placeholder="Pilih Provinsi"
                        {...params.inputProps}
                    />
                    <InputRightElement>
                        <ChevronDownIcon />
                    </InputRightElement>
                    </InputGroup>
                </div>
                )}
            />

            {!isEmpty(selectedProvince) && (
                <>
                    <Text mb={2} mt={4} fontSize="md">
                        Kabupaten/Kota
                    </Text>
                    <Autocomplete
                        value={selectedCityField}
                        options={listCity}
                        style={{ width: `${isMobile ? '100%' : '50%'}` }}
                        id="custom-input-demo"
                        getOptionLabel={(option) => option.city}
                        onChange={(_, values) =>
                        locationChangeHandler({index, fieldName: params.slugField, values, type: "city"})
                        }
                        renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <InputGroup size="md">
                            <Input
                                placeholder="Pilih Kabupaten/Kota"
                                {...params.inputProps}
                            />
                            <InputRightElement>
                                <ChevronDownIcon />
                            </InputRightElement>
                            </InputGroup>
                        </div>
                        )}
                    />
                </>
            )}

            {!isEmpty(selectedCity) && (
                <>
                    <Text mb={2} mt={4} fontSize="md">
                        Kecamatan - Kelurahan/Desa
                    </Text>
                    <Autocomplete
                        value={selectedPostcodeField}
                        options={listPostcode}
                        style={{ width: `${isMobile ? '100%' : '50%'}` }}
                        id="custom-input-demo"
                        getOptionLabel={(option) => option.zip_code}
                        onChange={(_, values) =>
                        locationChangeHandler({index, fieldName: params.slugField, values, type: "postcode"})
                        }
                        renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <InputGroup size="md">
                            <Input
                                placeholder="Pilih Kecamatan - Kelurahan"
                                {...params.inputProps}
                            />
                            <InputRightElement>
                                <ChevronDownIcon />
                            </InputRightElement>
                            </InputGroup>
                        </div>
                        )}
                    />
                </>
            )}
        </>
    );
}

export default LocationField;