import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/mystyles.css";
import qoreContext from "../qoreContext";
import { Box, Heading, Text, Image, Button, Link } from "@chakra-ui/react";

import confetti from "../assets/img/confetti.png";

function ThankYou() {
  let { id } = useParams();

  useEffect(() => {
    document.title = "Thank You!";
  }, []);

  const [filterProps] = useState({
    slugLink: id,
  });

  const { data: FormDetail } = qoreContext.views.allForm.useListRow({
    type: "predefined",
    order: "desc",
    ...filterProps,
  });

  return (
    <>
      <div className="full-page">
        <Box pb={6} className="form-body">
          {FormDetail[0] && 
            <Box
              mb={6}
              mt={6}
              align="center"
              width={{ base: "90%", md: "50%", lg: "50%" }}
              padding="32px 24px 24px 24px"
              background="white"
              borderWidth="1px"
              borderRadius="lg"
            >
              <Box mb={8}>
                <Image
                  src={
                    FormDetail[0].isUsingCustomThanks && FormDetail[0].thankyouImage
                      ? FormDetail[0].thankyouImage
                      : confetti
                  }
                  alt="Thank You!"
                  align-items="center"
                  justify="center"
                  maxWidth="75%"
                />
              </Box>
              <Heading as="h2" size="md" mb={2}>
                {FormDetail[0].isUsingCustomThanks ? FormDetail[0].name : 'Thank You!'}
              </Heading>
              <Heading as="h1" size="lg" mb={2}>
                {FormDetail[0].isUsingCustomThanks ? FormDetail[0].customThanks[0].mainText : 'Your answers has been submitted!'}
              </Heading>
              {FormDetail[0].isRedirectActive && (
                <Link
                  href={FormDetail[0].redirectOutsideData[0].redirectUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    color={FormDetail[0].redirectOutsideData[0].textColor}
                    bg={FormDetail[0].redirectOutsideData[0].mainColor}
                    mb={3}
                  >
                    {FormDetail[0].redirectOutsideData[0].redirectText}
                  </Button>
                </Link>
              )}
              {FormDetail[0].isUsingCustomThanks ?
                <Text mb={2} fontSize="md">
                  {FormDetail[0].customThanks[0].subMainText || "Want to submit again? Click "}
                  <Link href={`../${id}`} rel="noreferrer">
                    <b>{FormDetail[0]?.customThanks[0].clickableText || "here"}</b>
                  </Link>
                </Text>
              :
                <Text mb={2} fontSize="md">
                  Want to submit again? Click{" "}
                  <Link href={`../${id}`}>
                    <b>here</b>
                  </Link>
                </Text>
              }
            </Box>
          }
        </Box>
      </div>
    </>
  );
}

export default ThankYou;
