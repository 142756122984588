import React, {useEffect, useState} from 'react';
import {
    useParams
  } from "react-router-dom";
import {upperFirst, camelCase, isEmpty} from "lodash";
import axios from 'axios';
import '../assets/css/mystyles.css';
import qoreContext from '../qoreContext';
import {
    Button,
    Box,
    Heading,
    Text,
    Input,
    Image,
    Stack,
    RadioGroup,
    Radio,
    useToast,
    useMediaQuery,
    Wrap,
    WrapItem,
    InputGroup,
    InputRightElement,
    Checkbox,
    CheckboxGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    Icon,
    HStack,
    Textarea,
    Spacer,
    Flex
  } from "@chakra-ui/react"

import { Autocomplete } from "@material-ui/lab";
import { ChevronDownIcon, AttachmentIcon, CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import pdfImage from "../assets/img/pdf.png";
import LocationField from './components/LocationField';

const RadioStyle = {
    width: '20px',
    height: '20px',
    borderColor: 'rgba(10, 17, 45, 0.4)'
}

function FormPage () {
    const client = qoreContext.useClient();
    const [isMobile] = useMediaQuery("(max-width: 992px)");
    const [phoneNumber, setPhoneNumber] = useState("")
    const [FieldsbyForms, setFieldsbyForms] = useState([]);
    const [ReadOnlyFieldsbyForms, setReadOnlyFieldsbyForms] = useState([]);
    const [FormIsInactive, setFormIsInactive] = useState([]);
    const [FieldsbyFormsResult, setFieldsbyFormsResult] = useState([]);
    const [ReadOnlyFieldsbyFormsResult, setReadOnlyFieldsbyFormsResult] = useState([]);
    const [FileUpload, setFileUpload] = useState({file: null, variable_name: "init2"});
    const [FieldFilter, setFieldFilter] = useState({formId: "init"});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
    const [selectedFieldSlugField, setSelectedFieldSlugField] = useState(null);
    const [emptyFieldsWarning, setEmptyFieldsWarning] = useState([{field: 'init', fieldIndex: null}]);
    const [enableRadioField, setEnableRadioField] = useState(false);
    const [enableCheckboxField, setEnableCheckboxField] = useState(false);
    const [isDeadline, setIsDeadline] = useState(true);
    const [isLocation, setIsLocation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [formName, setFormName] = useState(null);
    const [baseUrl] = useState('https://p-qore-dot-pti-feedloop.et.r.appspot.com');
    const [projectToken, setProjectToken] = useState(null);
    const [tokenUser, setTokenUser] = useState(null);
    const [selectedOrgs, setSelectedOrgs] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectName] = useState('P Form');
    const [fieldIndex, setFieldIndex] = useState(null);
    const [formId, setFormId] = useState(null);

    const { insertRow: insertPredefinedData } = qoreContext.views.allPredefinedData.useInsertRow();

    const toast = useToast();
    let { id } = useParams();

    const [filterProps] = useState({
        slugLink: id,
    });

    const {data: FormList, status} = qoreContext.views.allForm.useListRow({
        type: "predefined",
        order: "desc",
        ...filterProps,
    });

    useEffect(() => {
        let ignore = false;
        const getToken = async () => {
          await axios.post(`${baseUrl}/login`, {
            email: process.env.REACT_APP_MAIL,
            password: process.env.REACT_APP_WORD,
          })
            .then((result) => {
              if (!ignore) {
                setTokenUser(result.data.token);
              }
            });
        };
    
        getToken();
    
        return () => { ignore = true; };
    }, [baseUrl]);
    
    useEffect(() => {
        let ignore = false;
        const getOrgs = async () => {
          if (tokenUser) {
            await axios.get(`${baseUrl}/orgs`,
              {
                headers: {
                  Authorization: `Bearer ${tokenUser}`,
                },
              })
              .then((result) => {
                const organizationsList = result.data.nodes;
                const filteredOrganizationsList = organizationsList.find((data) => data.name === "PT PTI");
                if (!ignore) {
                  setSelectedOrgs(filteredOrganizationsList.id);
                }
              });
          }
        };
    
        getOrgs();
    
        return () => { ignore = true; };
    }, [baseUrl, tokenUser]);
    
    useEffect(() => {
        let ignore = false;
        const getProjects = async () => {
          if (selectedOrgs && tokenUser) {
            await axios.get(`${baseUrl}/orgs/${selectedOrgs}/projects`,
              {
                headers: {
                  Authorization: `Bearer ${tokenUser}`,
                },
              })
              .then((result) => {
                if (!ignore) {
                  const selected = result.data.nodes.filter((project) => project.name === projectName);
                  setSelectedProject(selected[0].id);
                }
              });
          }
        };
    
        getProjects();
    
        return () => { ignore = true; };
    }, [baseUrl, selectedOrgs, tokenUser, projectName]);
    
    useEffect(() => {
        let ignore = false;
        const getTokenProject = async () => {
            if (selectedOrgs && tokenUser && selectedProject && baseUrl) {
                await axios({
                    method: 'post',
                    url: `${baseUrl}/orgs/${selectedOrgs}/projects/${selectedProject}/authenticate`,
                    headers: {
                        Authorization: `Bearer ${tokenUser}`,
                    },
                    data: {
                        token: tokenUser,
                    },
                })
                .then((result) => {
                    if (!ignore) {
                        setProjectToken(result.data.token);
                    }
                });
            }
        };
    
        getTokenProject();
    
        return () => { ignore = true; };
    }, [baseUrl, selectedOrgs, tokenUser, selectedProject]);

    useEffect(() => {
        if (FormList.length > 0) {
            setFieldFilter(FieldFilter => ({ ...FieldFilter, formId: FormList[0].id }));
        }
    }, [FormList])

    const {data: ReadOnlyFields} = qoreContext.views.allFields.useListRow({
        type: "predefined",
        order: "desc",
        isActive: true,
        isReadOnly: true,
        ...FieldFilter,
    });

    const {data: FieldList} = qoreContext.views.allFields.useListRow({
        type: "predefined",
        order: "desc",
        isActive: true,
        isReadOnly: false,
        ...FieldFilter,
    });

    useEffect(() => {
        if (formName) {
            document.title = formName;
        } else {
            document.title = "Halaman Form";
        }
    }, [formName])

    useEffect(() => {
        async function fetchData() {
            if (FormList.length === 1 && FormList[0].active) {

                if (FieldList.length) {
                    let idForm = FormList[0].id;
                    setFormId(idForm);

                    let FieldsbyForms2 = [];
                    let FieldsbyFormsResult2 = [];

                    let LinearScaleArray = {
                        indexField: null,
                        linear_array: null,
                        multipleAnswer: null
                    };

                    let tempIsLocation;

                    let tempMultiChoiceGrids = [];
                    let multiChoiceGridAnswer = [];
                    let multiChoiceRowNumber;
                    let multiChoiceRows;

                    let tempMultiCheckboxGrids = [];
                    let multiCheckboxGridAnswer = [];
                    let multiCheckboxRowNumber;
                    let multiCheckboxRows;
    
                    for (let FieldIndex = 0; FieldIndex < FieldList.length; FieldIndex++){
                        setFieldIndex(FieldIndex);

                        if (FieldList[FieldIndex].fieldType.displayField.includes("Short Answer") ||
                            FieldList[FieldIndex].fieldType.displayField.includes("Long Answer") ||
                            FieldList[FieldIndex].fieldType.displayField.includes("Multiple Choice") ||
                            FieldList[FieldIndex].fieldType.displayField.includes("Dropdown") ||
                            FieldList[FieldIndex].fieldType.displayField.includes("Checkbox") ||
                            FieldList[FieldIndex].fieldType.displayField.includes("File") ||
                            FieldList[FieldIndex].fieldType.displayField === "Date" ||
                            FieldList[FieldIndex].fieldType.displayField === "Location" ||
                            FieldList[FieldIndex].fieldType.displayField === "Multi Choice Grid" ||
                            FieldList[FieldIndex].fieldType.displayField === "Check Box Grid"
                        ) {
                            if (!tempIsLocation) {
                                tempIsLocation = FieldList[FieldIndex].fieldType.displayField === "Location";
                            }

                            if (FieldList[FieldIndex].fieldType.displayField === "Multi Choice Grid") {
                                multiChoiceGridAnswer = [];
                                multiChoiceRowNumber = FieldList[FieldIndex].multipleAnswer.row_length;
                                multiChoiceRows = FieldList[FieldIndex].multipleAnswer.row;
                                
                                for (let i = 0; i < multiChoiceRowNumber; i++) {
                                    multiChoiceGridAnswer.push({
                                        [multiChoiceRows[i].toString()]: null
                                    })
                                }

                                tempMultiChoiceGrids.push({
                                    index: FieldIndex,
                                    tempData: multiChoiceGridAnswer
                                })
                            }

                            if (FieldList[FieldIndex].fieldType.displayField === "Check Box Grid") {
                                multiCheckboxGridAnswer = [];
                                multiCheckboxRowNumber = FieldList[FieldIndex].multipleAnswer.row_length;
                                multiCheckboxRows = FieldList[FieldIndex].multipleAnswer.row;

                                for (let i = 0; i < multiCheckboxRowNumber; i++) {
                                    multiCheckboxGridAnswer.push({
                                        [multiCheckboxRows[i].toString()]: []
                                    })
                                }

                                tempMultiCheckboxGrids.push({
                                    index: FieldIndex,
                                    tempData: multiCheckboxGridAnswer
                                })
                            }

                            FieldsbyForms2.push({
                                form_id: idForm,
                                field_id: FieldList[FieldIndex].id,
                                form_name: FieldList[FieldIndex].formId.displayField,
                                slugField: FieldList[FieldIndex].slugField,
                                title: FieldList[FieldIndex].title,
                                field_type: FieldList[FieldIndex].fieldType,
                                indexField: FieldList[FieldIndex].indexField,
                                isRequired: FieldList[FieldIndex].isRequired,
                                multipleAnswer: FieldList[FieldIndex].multipleAnswer,
                                description: FieldList[FieldIndex].description,
                                image: FieldList[FieldIndex].image,
                                type: "primitive",
                                isReadOnly: FieldList[FieldIndex].isReadOnly,
                                defaultAnswer: FieldList[FieldIndex].defaultAnswer,
                                inputType: FieldList[FieldIndex].inputType
                            })
    
                        } else if (FieldList[FieldIndex].fieldType.displayField.includes("Linear Scale")) {
                            
                            const Length = FieldList[FieldIndex].multipleAnswer.scale_length;
                            const MinValue = parseInt(FieldList[FieldIndex].multipleAnswer.min_scale);

                            const linearArray = Array.from({length: Length}, (_, i) => i + MinValue);
                            let linearObjectArray = [];

                            for (let i = 0; i < linearArray.length; i++) {
                                linearObjectArray.push({
                                    nilai: linearArray[i].toString()
                                })
                            }

                            LinearScaleArray = {
                                indexField: FieldList[FieldIndex].indexField,
                                linear_array: linearObjectArray,
                                multipleAnswer: FieldList[FieldIndex].multipleAnswer
                            };

                            FieldsbyForms2.push({
                                form_id: idForm,
                                field_id: FieldList[FieldIndex].id,
                                form_name: FieldList[FieldIndex].formId.displayField,
                                slugField: FieldList[FieldIndex].slugField,
                                title: FieldList[FieldIndex].title,
                                field_type: FieldList[FieldIndex].fieldType,
                                indexField: FieldList[FieldIndex].indexField,
                                isRequired: FieldList[FieldIndex].isRequired,
                                multipleAnswer: LinearScaleArray,
                                description: FieldList[FieldIndex].description,
                                image: FieldList[FieldIndex].image,
                                type: "primitive",
                                isReadOnly: FieldList[FieldIndex].isReadOnly,
                                defaultAnswer: FieldList[FieldIndex].defaultAnswer,
                            })

                        } else {
                            if (!tempIsLocation) {
                                tempIsLocation =  FieldList[FieldIndex].inputType === "location";
                            }
    
                            FieldsbyForms2.push({
                                form_id: idForm,
                                field_id: FieldList[FieldIndex].id,
                                form_name: FieldList[FieldIndex].formId.displayField,
                                slugField: FieldList[FieldIndex].slugField,
                                title: FieldList[FieldIndex].title,
                                field_type: FieldList[FieldIndex].fieldType,
                                predefinedFieldType: FieldList[FieldIndex].inputType,
                                indexField: FieldList[FieldIndex].indexField,
                                isRequired: FieldList[FieldIndex].isRequired,
                                multipleAnswer: FieldList[FieldIndex].multipleAnswer,
                                description: FieldList[FieldIndex].description,
                                image: FieldList[FieldIndex].image,
                                type: "predefined",
                                isReadOnly: FieldList[FieldIndex].isReadOnly,
                                defaultAnswer: FieldList[FieldIndex].defaultAnswer,
                            });
                        }
                    }
    
                    let SortedFieldList = FieldList.sort((c,d)=> (c.indexField > d.indexField ? 1 : -1));
            
                    var a = null;

                    for (let PredefinedIndex = 0; PredefinedIndex < SortedFieldList.length ; PredefinedIndex++) {
                        if (SortedFieldList[PredefinedIndex].formId.id === idForm) {
                            a = SortedFieldList[PredefinedIndex].slugField.toString();
                            if (SortedFieldList[PredefinedIndex].isReadOnly) {
                                if (SortedFieldList[PredefinedIndex].inputType === 'location') {
                                    FieldsbyFormsResult2.push(
                                        {
                                            [camelCase(`Split ${a} Province`)]: JSON.parse(SortedFieldList[PredefinedIndex].defaultAnswer).province,
                                            [camelCase(`Split ${a} City`)]: JSON.parse(SortedFieldList[PredefinedIndex].defaultAnswer).city,
                                            [camelCase(`Split ${a} Postcode`)]: JSON.parse(SortedFieldList[PredefinedIndex].defaultAnswer).postcode
                                        }
                                    )
                                } else {
                                    FieldsbyFormsResult2.push({[a]: SortedFieldList[PredefinedIndex].defaultAnswer})
                                }
                            } else {
                                if (SortedFieldList[PredefinedIndex].inputType === 'location') {
                                    FieldsbyFormsResult2.push(
                                        {
                                            [camelCase(`Split ${a} Province`)]: null,
                                            [camelCase(`Split ${a} City`)]: null,
                                            [camelCase(`Split ${a} Postcode`)]: null
                                        }
                                    )
                                } else if (SortedFieldList[PredefinedIndex].fieldType.displayField === 'Multi Choice Grid') {
                                    for (let validateChoiceData = 0; validateChoiceData < tempMultiChoiceGrids.length ; validateChoiceData++) {
                                        if (tempMultiChoiceGrids[validateChoiceData].index === PredefinedIndex) {
                                            FieldsbyFormsResult2.push({[a]: tempMultiChoiceGrids[validateChoiceData].tempData})
                                        }
                                    }
                                    // FieldsbyFormsResult2.push({[a]: multiChoiceGridAnswer})
                                } else if (SortedFieldList[PredefinedIndex].fieldType.displayField === 'Check Box Grid') {
                                    for (let validateChckbxData = 0; validateChckbxData < tempMultiCheckboxGrids.length ; validateChckbxData++) {
                                        if (tempMultiCheckboxGrids[validateChckbxData].index === PredefinedIndex) {
                                            FieldsbyFormsResult2.push({[a]: tempMultiCheckboxGrids[validateChckbxData].tempData})
                                        }
                                    }
                                    // FieldsbyFormsResult2.push({[a]: multiCheckboxGridAnswer})
                                } else {
                                    FieldsbyFormsResult2.push({[a]: null})
                                }
                            }
                        }
                    }

                    setIsLocation(tempIsLocation);

                    if (FieldsbyFormsResult2.length === FieldsbyForms2.length) {
                        setFieldsbyForms(FieldsbyForms2.sort((a,b)=> (a.indexField > b.indexField ? 1 : -1)));                
                        setFieldsbyFormsResult(FieldsbyFormsResult2);
                    }
                    
                    setFormName(FieldsbyForms2[0].form_name);
                }
                
                if (ReadOnlyFields.length) {
                    let idForm = FormList[0].id;
                    let ReadOnlyFieldIndex = 0;

                    var ReadOnlyFieldsbyForms2 = [];
                    var ReadOnlyFieldsbyFormsResult2 = [];

                    for (ReadOnlyFieldIndex = 0; ReadOnlyFieldIndex < ReadOnlyFields.length; ReadOnlyFieldIndex++){
                        if (ReadOnlyFields[ReadOnlyFieldIndex].fieldType.displayField.includes("Short Answer") ||
                            ReadOnlyFields[ReadOnlyFieldIndex].fieldType.displayField.includes("Long Answer") ||
                            ReadOnlyFields[ReadOnlyFieldIndex].fieldType.displayField.includes("Dropdown") ||
                            ReadOnlyFields[ReadOnlyFieldIndex].fieldType.displayField === "Date"
                        ) {
                            ReadOnlyFieldsbyForms2.push({
                                form_id: idForm,
                                field_id: ReadOnlyFields[ReadOnlyFieldIndex].id,
                                form_name: ReadOnlyFields[ReadOnlyFieldIndex].formId.displayField,
                                slugField: ReadOnlyFields[ReadOnlyFieldIndex].slugField,
                                title: ReadOnlyFields[ReadOnlyFieldIndex].title,
                                field_type: ReadOnlyFields[ReadOnlyFieldIndex].fieldType,
                                indexField: ReadOnlyFields[ReadOnlyFieldIndex].indexField,
                                isRequired: ReadOnlyFields[ReadOnlyFieldIndex].isRequired,
                                multipleAnswer: ReadOnlyFields[ReadOnlyFieldIndex].multipleAnswer,
                                description: ReadOnlyFields[ReadOnlyFieldIndex].description,
                                image: ReadOnlyFields[ReadOnlyFieldIndex].image,
                                type: "primitive",
                                isReadOnly: ReadOnlyFields[ReadOnlyFieldIndex].isReadOnly,
                                defaultAnswer: 
                                    ReadOnlyFields[ReadOnlyFieldIndex].inputType === 'location' ? 
                                        JSON.parse(ReadOnlyFields[ReadOnlyFieldIndex].defaultAnswer)
                                        : ReadOnlyFields[ReadOnlyFieldIndex].defaultAnswer,
                                inputType: ReadOnlyFields[ReadOnlyFieldIndex].inputType
                            })
    
                        } else {
                            ReadOnlyFieldsbyForms2.push({
                                form_id: idForm,
                                field_id: ReadOnlyFields[ReadOnlyFieldIndex].id,
                                form_name: ReadOnlyFields[ReadOnlyFieldIndex].formId.displayField,
                                slugField: ReadOnlyFields[ReadOnlyFieldIndex].slugField,
                                title: ReadOnlyFields[ReadOnlyFieldIndex].title,
                                field_type: ReadOnlyFields[ReadOnlyFieldIndex].fieldType,
                                predefinedFieldType: ReadOnlyFields[ReadOnlyFieldIndex].inputType,
                                indexField: ReadOnlyFields[ReadOnlyFieldIndex].indexField,
                                isRequired: ReadOnlyFields[ReadOnlyFieldIndex].isRequired,
                                multipleAnswer: ReadOnlyFields[ReadOnlyFieldIndex].multipleAnswer,
                                description: ReadOnlyFields[ReadOnlyFieldIndex].description,
                                image: ReadOnlyFields[ReadOnlyFieldIndex].image,
                                type: "predefined",
                                isReadOnly: ReadOnlyFields[ReadOnlyFieldIndex].isReadOnly,
                                defaultAnswer: 
                                    ReadOnlyFields[ReadOnlyFieldIndex].inputType === 'location' ? 
                                        JSON.parse(ReadOnlyFields[ReadOnlyFieldIndex].defaultAnswer)
                                        : ReadOnlyFields[ReadOnlyFieldIndex].defaultAnswer,
                            });
                        }
                    }

                    let SortedReadOnlyFieldList = ReadOnlyFields.sort((c,d)=> (c.indexField > d.indexField ? 1 : -1));

                    var ab = null;
                    for (let PredefinedIndex2 = 0; PredefinedIndex2 < SortedReadOnlyFieldList.length ; PredefinedIndex2++) {
                        if (SortedReadOnlyFieldList[PredefinedIndex2].formId.id === idForm) {
                            ab = SortedReadOnlyFieldList[PredefinedIndex2].slugField.toString();
                            if (SortedReadOnlyFieldList[PredefinedIndex2].isReadOnly) {
                                if (SortedReadOnlyFieldList[PredefinedIndex2].inputType === 'location') {
                                    ReadOnlyFieldsbyFormsResult2.push(
                                        {
                                            [camelCase(`Split ${ab} Province`)]: JSON.parse(SortedReadOnlyFieldList[PredefinedIndex2].defaultAnswer).province,
                                            [camelCase(`Split ${ab} City`)]: JSON.parse(SortedReadOnlyFieldList[PredefinedIndex2].defaultAnswer).city,
                                            [camelCase(`Split ${ab} Postcode`)]: JSON.parse(SortedReadOnlyFieldList[PredefinedIndex2].defaultAnswer).postcode
                                        }
                                    )
                                } else {
                                    ReadOnlyFieldsbyFormsResult2.push({[ab]: SortedReadOnlyFieldList[PredefinedIndex2].defaultAnswer})
                                }
                            } else {
                                if (SortedReadOnlyFieldList[PredefinedIndex2].inputType === 'location') {
                                    ReadOnlyFieldsbyFormsResult2.push(
                                        {
                                            [camelCase(`Split ${ab} Province`)]: null,
                                            [camelCase(`Split ${ab} City`)]: null,
                                            [camelCase(`Split ${ab} Postcode`)]: null
                                        }
                                    )
                                } else {
                                    ReadOnlyFieldsbyFormsResult2.push({[ab]: null})
                                }
                            }
                        }
                    }

                    setReadOnlyFieldsbyForms(ReadOnlyFieldsbyForms2.sort((a,b)=> (a.indexField > b.indexField ? 1 : -1)));                
                    setReadOnlyFieldsbyFormsResult(ReadOnlyFieldsbyFormsResult2);
                }

            } else if (FormList.length === 1 && !FormList[0].active) {

                var set = {
                    message : FormList[0].inactive_message ? FormList[0].inactive_message : "Maaf, Form yang anda tuju sudah tidak aktif"
                }
                setFormIsInactive(set);

            } else if (status === "success" && FormList.length === 0) {
                    
                window.location.href = "/";

            }
        }

        fetchData();
        
    }, [FormList, FieldList, FormIsInactive, ReadOnlyFields, status, fieldIndex])

    useEffect(() => {
        if (FormList.length > 0) {
            const dateNow = new Date();

            let deadlineStatus = true;
            let deadlineDate = FormList[0].deadlineDate;
            if (deadlineDate) {
                deadlineDate = new Date(FormList[0].deadlineDate);
                deadlineStatus = dateNow <= deadlineDate;
                setIsDeadline(deadlineStatus);
            } else {
                setIsDeadline(true);
            }
            

            if (!deadlineStatus) {
                var set = {
                    message: FormList[0].deadlineMessage || "Maaf, Form yang anda tuju sudah tidak aktif"
                }

                setFormIsInactive(set)
            }
        }
    }, [FormList]);

    const myChangeHandler = (index, event, value) => {

        var tempFieldsbyFormsResult = FieldsbyFormsResult;
        tempFieldsbyFormsResult[index][event.target.name] = event.target.value;

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const privacyPolicyHandler = () => {
        setPrivacyPolicy(!privacyPolicy);
    }

    const changePhoneNumber = (index, event,) => {
        const isNumber = /^\d+$/.test(event.target.value);
        if (isNumber || event.target.value === "") {
            const tempFieldsbyFormsResult = FieldsbyFormsResult;
            tempFieldsbyFormsResult[index][event.target.name] = event.target.value;
            setFieldsbyFormsResult(tempFieldsbyFormsResult);
            setPhoneNumber(event.target.value)
        } else {
            setPhoneNumber(phoneNumber)
        }
    }


    const dropdownChangeHandler = ({ index, fieldName, value }) => {
      var tempFieldsbyFormsResult = [...FieldsbyFormsResult];
      tempFieldsbyFormsResult[index][fieldName] = value;
      setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const DateChangeHandler = (index, event, value) => {

        var tempFieldsbyFormsResult = FieldsbyFormsResult;

        let date = new Date(event.target.value);
        let finalDate = date.toLocaleDateString('en-GB');

        tempFieldsbyFormsResult[index][event.target.name] = finalDate;

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const RadioChangeHandler = (index, event, name, className) => {

        var tempFieldsbyFormsResult = FieldsbyFormsResult;

        tempFieldsbyFormsResult[index][name] = {};

        if (className === "input") {
            setEnableRadioField(true);
            tempFieldsbyFormsResult[index][name] = event.target.value;
        } else {
            tempFieldsbyFormsResult[index][name] = event;
        }

        if (tempFieldsbyFormsResult[index][name] === "Others" || className === "input") {
            setEnableRadioField(true);
        } else {
            setEnableRadioField(false);
        }

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const MultiChoiceGrid = (index, event, name, rowName, rowIndex) => {
        var tempFieldsbyFormsResult = FieldsbyFormsResult;
        var tempMultiChoiceResult = tempFieldsbyFormsResult[index][name];
        
        tempMultiChoiceResult[rowIndex][rowName] = event;
        
        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const MultiCheckboxGrid = (index, event, name, rowName, rowIndex) => {
        let tempFieldsbyFormsResult = FieldsbyFormsResult;
        let tempMultiChoiceResult = tempFieldsbyFormsResult[index][name];
        let data = tempMultiChoiceResult[rowIndex][rowName];

        if (data) {
            let find = data.indexOf(event.target.value);
            if (find > -1) {
                data.splice(find, 1);
            } else {
                data.push(event.target.value);
            }
        } else {
            data = [];
        }

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const CheckboxChangeHandler = (index, event, name, className) => {

        var tempFieldsbyFormsResult = FieldsbyFormsResult;

        let a = "default_choices";
        let b = "other_choices";

        if (className === "input") {
            setEnableCheckboxField(true);
            Object.assign(tempFieldsbyFormsResult[index][name], {[b]: event.target.value} );
        } else if ((className === "Checkbox" || className === "checkbox") && (tempFieldsbyFormsResult[index][name] !== null) && (tempFieldsbyFormsResult[index][name][b] !== null)) {
            setEnableCheckboxField(true);
            Object.assign(tempFieldsbyFormsResult[index][name], {[a]: event} );
        } else if ((className === "Checkbox" || className === "checkbox") && (tempFieldsbyFormsResult[index][name] !== null)) {
            Object.assign(tempFieldsbyFormsResult[index][name], {[b]: null} );
        } else if (className === "Checkbox" || className === "checkbox") {
            tempFieldsbyFormsResult[index][name] = {};
            Object.assign(tempFieldsbyFormsResult[index][name], {[a]: event} );
        } else {
            tempFieldsbyFormsResult[index][name] = event;
        }

        if (tempFieldsbyFormsResult[index][name]['default_choices']){
            if (tempFieldsbyFormsResult[index][name]['default_choices'].includes("Others") || className === "input") {
                setEnableCheckboxField(true);
            } else {
                tempFieldsbyFormsResult[index][name]['other_choices'] = '';
                setEnableCheckboxField(false);
            }
        }

        setFieldsbyFormsResult(tempFieldsbyFormsResult)
    };

    const imageUpload = (index, slugField) => {
        setSelectedFieldIndex(index);
        setSelectedFieldSlugField(slugField.slugField);
        setIsImageModalOpen(true);
    };

    const closeImageModal = () => {
        setIsImageModalOpen(false);
        setFileUpload({file: null, variable_name: null});
        setSelectedFieldIndex(null);
        setSelectedFieldSlugField(null);
    };

    const deleteFile = () => {
        setFileUpload({file: null, variable_name: null});
    }

    const submitFile = (index, slug) => {
        if (FileUpload[index][slug].file) {
            setIsImageModalOpen(false);
        } else {
            toast({
                title: 'Error',
                status: "error",
                description: "Mohon lampirkan berkas terlebih dahulu",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
        }
    };

    const FileChangeHandler = (index, event, name, value) => {
        var tempFileUpload = FileUpload
        var tempFieldsbyFormsResult = FieldsbyFormsResult

        if(event.target.files[0].size > 4194304){
            toast({
                title: 'Ukuran berkas melebihi 4 MB',
                status: "error",
                description: "Mohon pilih berkas dengan ukuran yang lebih kecil",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
            event.target.value = null;
        } else {
            switch(event.target.files[0].type) {
            case "application/pdf":
                tempFileUpload = {
                    "file": event.target.files[0],
                    "variable_name": event.target.name,
                }
                setFileUpload(FieldsbyFormsResult)

                break;
            case "image/jpg":
                tempFileUpload = {
                    "file": event.target.files[0],
                    "variable_name": event.target.name,
                }
                setFileUpload(FieldsbyFormsResult)

                break;
            case "image/png":
                tempFileUpload = {
                    "file": event.target.files[0],
                    "variable_name": event.target.name,
                }
                setFileUpload(FieldsbyFormsResult)

                break;
            case "image/jpeg":
                tempFileUpload = {
                    "file": event.target.files[0],
                    "variable_name": event.target.name,
                }
                setFileUpload(FieldsbyFormsResult)
                
                break;
            default:
                toast({
                    title: 'Berkas tidak didukung',
                    status: "error",
                    description: "Mohon pilih berkas berjenis dokumen (PDF) atau gambar (JPG, PNG, JPEG)",
                    duration: 1500,
                    position: "top",
                    isClosable: true,
                });
                event.target.value = null;
            }
        }
        tempFieldsbyFormsResult[index][event.target.name] = tempFileUpload
        setFieldsbyFormsResult(tempFieldsbyFormsResult)
    };


    var dateFormatter = function dateFormatter(date) {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let dateData = new Date(date);
        let finalDate = `${dateData.toLocaleString('id-ID', options)}. Pukul ${dateData.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})}`;

        return finalDate;
    }

    const validationHandler = () => {
         /* eslint-disable */
        let flag = false;
        FieldsbyFormsResult.forEach((item) => {
            if (item.email) {
               const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.email)
               if (!isEmail) {
                   flag = {msg: "Maaf, Format email salah!"}
               } 
            } else if (item.phoneNumber) {
                const isNumber = /^\d+$/.test(item.phoneNumber);
                 if (!isNumber || item.phoneNumber.length <= 10 || item.phoneNumber.length > 13 ) {
                   flag = {msg: "Maaf, Format phone number salah!"}
                 } 
            }
        })

        if(flag) {
            toast({
                title: "Error",
                status: "error",
                description: flag.msg,
                duration: 1500,
                position: "top",
                isClosable: true,
            });
            return false
        } else {
            return true
        }
    }

    const handleSubmit = async() => {
        setIsSubmitting(true);
        const isValid = validationHandler();
        if (!isValid) {
          setIsSubmitting(false);
          return;
        }
        var Results = {};
        var FieldName = null;
        var ReadOnlyFieldName = null;
        var FormResults = FieldsbyFormsResult;
        var ReadOnlyFormResults = ReadOnlyFieldsbyFormsResult;
        var FieldDetails = FieldsbyForms;
        var ReadOnlyFieldDetails = ReadOnlyFieldsbyForms;
        let EmptyFieldsToast = false;

        let PredefinedFieldResults = {};

        let header = { Authorization: `Bearer ${projectToken}` }

        var EmptyFields = [];

        for (let l = 0; l< ReadOnlyFormResults.length; l++){

            if (ReadOnlyFieldDetails[l].inputType === "location" || ReadOnlyFieldDetails[l].predefinedFieldType === "location") {   
                Object.keys(ReadOnlyFormResults[l]).forEach((addressItem) => {
                    Object.assign(Results, {[addressItem]: ReadOnlyFormResults[l][addressItem].toString()});

                    EmptyFields.push({
                        field: "OK",
                        fieldIndex: l
                    });
                })
            } else {
                ReadOnlyFieldName = Object.keys(ReadOnlyFormResults[l]).toString();
                Object.assign(Results, {[ReadOnlyFieldName]: Object.values(ReadOnlyFormResults[l]).toString()});
    
                EmptyFields.push({
                    field: "OK",
                    fieldIndex: l
                });
            }

            setEmptyFieldsWarning(EmptyFields);
        }

        for (let i = 0; i < FormResults.length ; i++)
        {
            if (FieldDetails[i].inputType === "location" || FieldDetails[i].predefinedFieldType === "location") {
                FieldName = FieldDetails[i].slugField
            } else {
                FieldName = Object.keys(FormResults[i]).toString();
            }

            switch (FieldName === FieldDetails[i].slugField) {
                case true:
                    if (FieldDetails[i].predefinedFieldType) {
                        switch (FieldDetails[i].predefinedFieldType){
                            case 'checkbox':
                            if (Object.values(FormResults[i]).toString() === "") {
                                if (FieldsbyForms[i].isRequired) {         
                                    EmptyFieldsToast = true;
                                    EmptyFields.push({
                                        field: FieldsbyForms[i].title,
                                        fieldIndex: i
                                    });
                                    break;
                                } else {
                                    Object.assign(Results, FormResults[i]);
                                    Object.assign(PredefinedFieldResults, FormResults[i]);
                                }
                            } else {
                                Object.assign(Results, FormResults[i]);
                                Object.assign(PredefinedFieldResults, FormResults[i]);
                            }
    
                            EmptyFields.push({
                                field: "OK",
                                fieldIndex: i
                            });
    
                            break;
                            case 'radio':
                            if (Object.values(FormResults[i]).toString() === "") {
                                if (FieldsbyForms[i].isRequired) {         
                                    EmptyFieldsToast = true;
                                    EmptyFields.push({
                                        field: FieldsbyForms[i].title,
                                        fieldIndex: i
                                    });
                                    break;
                                } else {
                                    Object.assign(Results, FormResults[i]);
                                    Object.assign(PredefinedFieldResults, FormResults[i]);
                                }
                            } else {
                                Object.assign(Results, FormResults[i]);
                                Object.assign(PredefinedFieldResults, FormResults[i]);
                            }
    
                            EmptyFields.push({
                                field: "OK",
                                fieldIndex: i
                            });
    
                            break;
                            case "file":
                            if (Object.values(FormResults[i]).toString() === '') {
                                if (FieldsbyForms[i].isRequired) {         
                                    EmptyFieldsToast = true;
                                    EmptyFields.push({
                                        field: FieldsbyForms[i].title,
                                        fieldIndex: i
                                    });
                                    break;
                                } else {
                                    Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                    Object.assign(PredefinedFieldResults, {[FieldName]: Object.values(FormResults[i]).toString()});
                                }
                            } else {
                                Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                Object.assign(PredefinedFieldResults, {[FieldName]: Object.values(FormResults[i]).toString()});
                            }
    
                            EmptyFields.push({
                                field: "OK",
                                fieldIndex: i
                            });
    
                            break;
                            case "location":
                                let isEmptyLocationSubmitted = false;
                                Object.keys(FormResults[i]).forEach((addressItem) => {
                                    if (isEmpty(FormResults[i][addressItem])) {
                                        if (FieldsbyForms[i].isRequired) {
                                            if (!isEmptyLocationSubmitted) {
                                                isEmptyLocationSubmitted = true;
                                                EmptyFieldsToast = true;
                                                EmptyFields.push({
                                                    field: FieldsbyForms[i].title,
                                                    fieldIndex: i
                                                });
                                            }
                                        }
                                    } else {
                                        Object.assign(Results, {[addressItem]: FormResults[i][addressItem].toString()});
                                        Object.assign(PredefinedFieldResults, {[addressItem]: FormResults[i][addressItem].toString()});
                                    }
                                })
                            break;
                            default:
                            if (Object.values(FormResults[i]).toString() === '') {
                                if (FieldsbyForms[i].isRequired) {         
                                    EmptyFieldsToast = true;
                                    EmptyFields.push({
                                        field: FieldsbyForms[i].title,
                                        fieldIndex: i
                                    });
                                    break;
                                } else {
                                    Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                    Object.assign(PredefinedFieldResults, {[FieldName]: Object.values(FormResults[i]).toString()});
                                }
                            } else {
                                Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                Object.assign(PredefinedFieldResults, {[FieldName]: Object.values(FormResults[i]).toString()});
                            }
    
                            EmptyFields.push({
                                field: "OK",
                                fieldIndex: i
                            });
                        }
                    } else {
                        switch (FieldDetails[i].field_type.displayField){
                            case "Checkbox":
                                if (Object.values(FormResults[i]).toString() === "") {
                                    if (FieldsbyForms[i].isRequired) {
                                        EmptyFieldsToast = true;      
                                        EmptyFields.push({
                                            field: FieldsbyForms[i].title,
                                            fieldIndex: i
                                        });
                                        break;
                                    } else {
                                        Object.assign(Results, FormResults[i]);
                                    }
                                } else {
                                    Object.assign(Results, FormResults[i]);
                                }
        
                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
    
                            break;
                            case "Multiple Choice":
                                if (Object.values(FormResults[i]).toString() === "") {
                                    if (FieldsbyForms[i].isRequired) {         
                                        EmptyFieldsToast = true;
                                        EmptyFields.push({
                                            field: FieldsbyForms[i].title,
                                            fieldIndex: i
                                        });
                                        break;
                                    } else {
                                        Object.assign(Results, FormResults[i]);
                                    }
                                } else {
                                    Object.assign(Results, FormResults[i]);
                                }
        
                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
    
                            break;
                            case "File":
                                if (Object.values(FormResults[i]).toString() === '') {
                                    if (FieldsbyForms[i].isRequired) {         
                                        EmptyFieldsToast = true;
                                        EmptyFields.push({
                                            field: FieldsbyForms[i].title,
                                            fieldIndex: i
                                        });
                                        break;
                                    } else {
                                        Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                    }
                                } else {
                                    Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                }
        
                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
    
                            break;
                            case "Location":
                                let isEmptyLocationSubmitted = false;
                                Object.keys(FormResults[i]).forEach((addressItem) => {
                                    if (isEmpty(FormResults[i][addressItem])) {
                                        if (FieldsbyForms[i].isRequired) {
                                            if (!isEmptyLocationSubmitted) {
                                                isEmptyLocationSubmitted = true;
                                                EmptyFieldsToast = true;
                                                EmptyFields.push({
                                                    field: FieldsbyForms[i].title,
                                                    fieldIndex: i
                                                });
                                            }
                                        }
                                    } else {
                                        Object.assign(Results, {[addressItem]: FormResults[i][addressItem].toString()});
                                    }
                                })
                            break;
                            case "Multi Choice Grid":
                                Object.keys(FormResults[i]).forEach((choiceItem) => {
                                    for (let j = 0; j < FormResults[i][choiceItem].length; j++) {

                                        let fullValue = FormResults[i][choiceItem];
                                        let resultMultiChoice = Object.entries(FormResults[i][choiceItem][j]);
                                        let valueResultMultiChoice = resultMultiChoice[0][1];

                                        if (valueResultMultiChoice && fullValue) {
                                            Object.assign(Results, FormResults[i]);
                                            continue;
                                        } else {
                                            if (FieldsbyForms[i].isRequired) {         
                                                EmptyFieldsToast = true;
                                                EmptyFields.push({
                                                    field: FieldsbyForms[i].title,
                                                    fieldIndex: i
                                                });
                                                break;
                                            } else {
                                                Object.assign(Results, FormResults[i]);
                                                break;
                                            }
                                        }
                                    }
                                })

                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
                                
                            break;
                            case "Check Box Grid":
                                Object.keys(FormResults[i]).forEach((choiceItem) => {
                                    for (let j = 0; j < FormResults[i][choiceItem].length; j++) {

                                        let fullValue = FormResults[i][choiceItem];
                                        let resultMultiChoice = Object.entries(FormResults[i][choiceItem][j]);
                                        let valueResultMultiChoice = resultMultiChoice[0][1];

                                        let fullValueCheck = false;

                                        for (let k = 0; k < fullValue.length; k++) {
                                            if (isEmpty(Object.values(fullValue[k])[0])){
                                                fullValueCheck = false;
                                                break;
                                            } else {
                                                fullValueCheck = true;
                                                continue;
                                            }
                                        }

                                        if (valueResultMultiChoice && fullValueCheck) {
                                            Object.assign(Results, FormResults[i]);
                                            continue;
                                        } else {
                                            if (FieldsbyForms[i].isRequired) {         
                                                EmptyFieldsToast = true;
                                                EmptyFields.push({
                                                    field: FieldsbyForms[i].title,
                                                    fieldIndex: i
                                                });
                                                break;
                                            } else {
                                                Object.assign(Results, FormResults[i]);
                                                break;
                                            }
                                        }
                                    }
                                })

                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
                                
                            break;
                            case "Linear Scale":
                                if (Object.values(FormResults[i]).toString() === "") {
                                    if (FieldsbyForms[i].isRequired) {         
                                        EmptyFieldsToast = true;
                                        EmptyFields.push({
                                            field: FieldsbyForms[i].title,
                                            fieldIndex: i
                                        });
                                        break;
                                    } else {
                                        Object.assign(Results, FormResults[i]);
                                    }
                                } else {
                                    Object.assign(Results, FormResults[i]);
                                }
        
                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
    
                            break;
                            default:
                                if (Object.values(FormResults[i]).toString() === '') {
                                    if (FieldsbyForms[i].isRequired) {         
                                        EmptyFieldsToast = true;
                                        EmptyFields.push({
                                            field: FieldsbyForms[i].title,
                                            fieldIndex: i
                                        });
                                        break;
                                    } else {
                                        Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                    }
                                } else {
                                    Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                                }
        
                                EmptyFields.push({
                                    field: "OK",
                                    fieldIndex: i
                                });
                        }
                    }

                    setEmptyFieldsWarning(EmptyFields);
                    break;

                case false:
                    break;
                default:
                    toast({
                        title: 'Ada Error, Bung!',
                        status: "error",
                        description: "Terjadi Kesalahan Sistem",
                        duration: 1500,
                        position: "top",
                        isClosable: true,
                    });
                    setIsSubmitting(false);
            }
        }

        if (EmptyFieldsToast === true) {
            toast({
                title: "Error",
                status: "error",
                description: "Maaf, ada isian wajib yang belum terisi, mohon diisi.",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
        }
        
        const deleteNonAlfaNumeric = FieldsbyForms[0].form_name.replace(/[^A-Za-z0-9 ]/g, ' ');
        const camelCaseFormatted = upperFirst(camelCase(deleteNonAlfaNumeric));
        let FormLink = "all" + camelCaseFormatted
        
        for (let i = 0; i < FileUpload.length ; i++){
            if((typeof Object.values(FileUpload[i])[0] == 'object') && Object.values(FileUpload[i])[0].file){
                const FileURL = await qoreContext.client.view("allFields").upload(Object.values(FileUpload[i])[0].file);
                const FileName = Object.values(FileUpload[i])[0].variable_name
                Object.assign(Results, {[FileName]: FileURL});
            } else{
                console.log("No Files!");
            }
        }

        for (let indexCheck = 0; indexCheck < EmptyFields.length ; indexCheck++){
            if (EmptyFields[indexCheck].field === Object.keys(Results).toString()) {
                setIsSubmitting(false);
                return;
            } else if (EmptyFields[indexCheck].field === "OK") {
                console.log("Clear!")
            } else {
                setIsSubmitting(false);
                return;
            }
        }
        
        let URL = `https://p-qore-dot-pti-feedloop.et.r.appspot.com/rQqa6x6rBFazqvD/${FormLink}/rows`;

        await axios({
            method: "post",
            url: URL,
            data: Results,
            headers: header, 
        })
        .then(async() => {
            try {
                const feedback = await insertPredefinedData(PredefinedFieldResults);
                await client.view('allPredefinedData').addRelation(feedback.id, {
                    relatedForm: [formId],
                });
                window.location.assign("/thankyou/" + id);
            } catch (error) {
                toast({
                    title: "Error",
                    status: "error",
                    description: "Telah terjadi kesalahan sistem",
                    duration: 1500,
                    position: "top",
                    isClosable: true,
                });
                setIsSubmitting(false);
            }
        })
        .catch(function (error) {
            console.log(error);
            toast({
                title: "Terjadi Error!",
                status: "error",
                description: "Terjadi kesalahan sistem",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
            setIsSubmitting(false);
        })
    };

    function urlify(params) {
        const { title, isRequired } = params;
        var urlRegex = /(https?:\/\/[^\s]+)|(www?.[^\s]+)/g;
        return `${title.replace(urlRegex, function (url) {
          return '<a style="color:blue;font-style:italic;" href="' + url.replace("www." ,"https://") + '">' + url + "</a>";
        })} ${isRequired? '<span style="color:red;">*</span>' : ""}`
    }

    return(
        <>
        { FormList.length === 1 ?
        <Box className="full-page" style={{ backgroundColor: FormList[0].bgHexColor }}>
            { FormList[0].active && isDeadline ? 
                <Box pb={6} className="form-body">
                    <Box mb={6} mt={6} width={{ base: "90%", md: "90%", lg: "50%" }} style={{ overflow: 'hidden' }} background="white" borderWidth="1px" borderRadius="lg">
                        <Box padding="24px 24px 24px 24px" background="white" >
                            {FieldsbyForms[0] ? 
                            <>
                                <Heading as="h1" size="lg" style={{ fontSize: '36px' }} mb={2}>
                                    {FieldsbyForms[0].form_name}
                                </Heading>
                                <pre   
                                    style={{
                                        marginBottom: "16px",
                                        fontSize:"16px",
                                        fontFamily: "var(--chakra-fonts-body)",
                                        whiteSpace:"pre-wrap"
                                    }}
                                >
                                    {FormList[0].description}
                                </pre>
                            </>
                            :
                            <>
                                <Heading as="h1" size="lg" mb={2}>
                                    .....
                                </Heading>
                            </>
                        }
                            <Text fontSize="md" mb={2} color="#EF5DA8">
                            *) Wajib Diisi
                            </Text>
                        </Box>
                        <Image m="0 auto" src={FormList[0].headerImg} alt="Header Banner" display={FormList[0].headerImg ? 'visible' : 'none'}/>  
                    </Box>
                    {ReadOnlyFieldsbyForms !== undefined && ReadOnlyFieldsbyForms.length > 0 ?
                    <Box width={{ base: "90%", md: "90%", lg: "50%"}} mb={6} background="white" borderWidth="1px" borderRadius="lg">
                        {
                            ReadOnlyFieldsbyForms.map((params,index) =>
                                <>
                                    <Box key={index} padding="24px 24px 24px 24px">
                                        {params.image ? 
                                            <Box mb={3}>
                                                <Image src={params.image} alt={params.slugField} align-items="center" justify="center" />
                                            </Box>
                                        : null}
                                        <Text mb={params.isReadOnly ? 2 : 4} fontSize="lg">
                                            {params.isRequired ? <><b>{params.title}&nbsp;</b><b style={{color: "red"}}>*</b></> 
                                            : <b>{params.title}</b>}
                                        </Text>
                                        { params.field_type.displayField === 'Short Answer' || params.field_type.displayField === 'Dropdown' ?
                                        <Text fontSize="xl">
                                            {params.defaultAnswer}
                                        </Text>                            
                                        : params.field_type.displayField === 'Location' || params.predefinedFieldType === 'location' ? (
                                            <>
                                                {!isEmpty(params.defaultAnswer.province) && (
                                                    <>
                                                        <Text mb={2} fontSize="md">
                                                            <b>Provinsi</b>
                                                        </Text>
                                                        <Text mb={2} fontSize="md">
                                                            {params.defaultAnswer.province}
                                                        </Text>
                                                    </>
                                                )}
                                                {!isEmpty(params.defaultAnswer.city) && (
                                                    <>
                                                        <Text mb={2} mt={4} fontSize="md">
                                                            <b>Kabupaten/Kota</b>
                                                        </Text>
                                                        <Text mb={2} fontSize="md">
                                                            {params.defaultAnswer.city}
                                                        </Text>
                                                    </>
                                                )}
                                                {!isEmpty(params.defaultAnswer.postcode) && (
                                                    <>
                                                        <Text mb={2} mt={4} fontSize="md">
                                                            <b>Kecamatan - Kelurahan/Desa</b>
                                                        </Text>
                                                        <Text mb={2} fontSize="md">
                                                            {params.defaultAnswer.postcode}
                                                        </Text>
                                                    </>
                                                )}
                                            </>
                                        ) : params.field_type.displayField === 'Long Answer' ?
                                        <Textarea>
                                            {params.defaultAnswer}
                                        </Textarea>   
                                        : params.field_type.displayField === 'Date' || params.field_type.displayField.includes("Date") ?
                                        <Text fontSize="xl">
                                            {dateFormatter(params.defaultAnswer)}
                                        </Text>  
                                        : params.predefinedFieldType && (params.predefinedFieldType === 'date' || params.predefinedFieldType === 'Date') ?
                                        <Text fontSize="xl">
                                            {dateFormatter(params.defaultAnswer)}
                                        </Text>  
                                        : params.predefinedFieldType && (params.predefinedFieldType === 'text' || params.predefinedFieldType === 'dropdown') ?
                                        <Text fontSize="xl">
                                            {params.defaultAnswer}
                                        </Text>   
                                        : params.predefinedFieldType && params.predefinedFieldType === 'textarea' ?
                                        <Textarea>
                                            {params.defaultAnswer}
                                        </Textarea>
                                        : 
                                        <Text fontSize="xl">
                                            {params.defaultAnswer}
                                        </Text>
                                        }
                                    </Box>
                                    {
                                    index === ReadOnlyFieldsbyForms.length - 1 ? null :
                                        <hr class="solid" style={{borderTop: `2px solid ${FormList[0].bgHexColor || '#D1D9F5'}`}}/>
                                    }
                                </>
                            )
                        }
                    </Box>
                    : null }
                    <Box width={{ base: "90%", md: "90%", lg: "50%"}} mb={6} background="white" borderWidth="1px" borderRadius="lg">
                    {FieldsbyForms !== undefined && FieldsbyForms.length > 0 ? FieldsbyForms.map((params,index) =>
                    <>
                        <Box key={params.field_id} padding="32px 24px 32px 24px">
                            {params.image ? 
                                <Box mb={3}>
                                    <Image src={params.image} alt={params.slugField} align-items="center" justify="center" />
                                </Box>
                            : null}
                            <Text mb={params.isReadOnly ? 2 : params.description === "" ? 4 : 0}  fontSize="lg" dangerouslySetInnerHTML={{__html:urlify(params)}} fontWeight="bold"/>
                            <Text fontSize="14px" mb="12px" color="#808080">{params.description}</Text>

                            {params.field_type.displayField === 'Location' || params.predefinedFieldType === 'location' ? (
                                <LocationField
                                    isLocation={isLocation}
                                    FieldsbyFormsResult={FieldsbyFormsResult}
                                    setFieldsbyFormsResult={setFieldsbyFormsResult}
                                    index={index}
                                    params={params}
                                />
                            ) :
                            params.field_type.displayField === 'Short Answer' ?
                            <Input 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                resize="vertical"
                                type="text" 
                                onChange={(event) => myChangeHandler(index, event)}
                            />                            
                            : params.field_type.displayField === 'Long Answer' ?
                            <Textarea 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                type="text" 
                                onChange={(event) => myChangeHandler(index, event)}
                            />    
                            : params.field_type.displayField === 'Date' || params.field_type.displayField.includes("Date")?
                            <Input 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                type="date" 
                                onChange={(event) => DateChangeHandler(index, event)}
                            />    
                            : params.predefinedFieldType && (params.predefinedFieldType === 'date' || params.predefinedFieldType === 'Date') ?
                            <Input 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                type="date" 
                                onChange={(event) => DateChangeHandler(index, event)}
                            />  
                            : params.predefinedFieldType && params.predefinedFieldType === 'text' ?
                                params.title === "Phone Number"? 
                                    <Input
                                        className="input"
                                        type="text" 
                                        id={params.slugField}
                                        name={params.slugField}
                                        onChange={(event) => changePhoneNumber(index, event)}
                                        value={phoneNumber}
                                    />   
                                    :   <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            onChange={(event) => myChangeHandler(index, event)}
                                        />   
                            : params.predefinedFieldType && params.predefinedFieldType === 'textarea' ?
                            <Textarea 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                type="text" 
                                onChange={(event) => myChangeHandler(index, event)}
                            />  
                            : params.predefinedFieldType && params.predefinedFieldType.includes("checkbox") ?
                            <CheckboxGroup id={params.slugField} colorScheme="blue" name={params.slugField} type={params.field_type.displayField} onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, params.predefinedFieldType)}>
                                <Wrap 
                                spacing={params.multipleAnswer.choice.map(char => (char.image ? "3em" : "1em"))} 
                                direction={params.multipleAnswer.choice.map(char => (char.image ? "row" : "column"))}
                                mb={4}
                                >
                                    {
                                    params.multipleAnswer.choice.map(char => (
                                    <WrapItem>
                                        <Stack>
                                            <Box>
                                                {char.image ?  
                                                <Checkbox value={char.text}>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            padding: "16px",
                                                            maxHeight: "fit-content",
                                                            width: "256px",
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                            marginBottom: "16px",
                                                        }}
                                                    >
                                                        <Image src={char.image} alt={char.image} mb={3} boxSize="100%"/>
                                                        {char.text}
                                                    </div>
                                                </Checkbox>
                                                : 
                                                <Checkbox value={char.text}>{char.text}</Checkbox>
                                                }
                                                
                                            </Box>
                                        </Stack>
                                    </WrapItem>
                                    ))
                                    }
                                    {params.multipleAnswer.other ? 
                                    <Stack>
                                        <Checkbox value="Others" mb={1}>Lainnya</Checkbox>
                                        {enableCheckboxField ? 
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        :
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text"
                                            disabled 
                                            onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        }
                                    </Stack>
                                    : null}
                                </Wrap>
                            </CheckboxGroup>
                            : params.predefinedFieldType && params.predefinedFieldType.includes("radio") ?
                            <RadioGroup id={params.slugField} name={params.slugField} type={params.field_type.displayField} onChange={(event) => RadioChangeHandler(index, event, params.slugField, params.field_type.displayField)}>
                                <Wrap 
                                spacing={params.multipleAnswer.choice.map(char => (char.image ? "3em" : "1em"))} 
                                direction={params.multipleAnswer.choice.map(char => (char.image ? "row" : "column"))}
                                >
                                    {
                                    params.multipleAnswer.choice.map(char => (
                                    <WrapItem>
                                        <Stack>
                                            <Box>
                                                {char.image ?  
                                                <Radio value={char.text} key={char.text}>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            padding: "16px",
                                                            maxHeight: "fit-content",
                                                            width: "256px",
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                            marginBottom: "16px",
                                                        }}
                                                    >
                                                        <Image src={char.image} alt={char.image} mb={3} boxSize="100%"/>
                                                        {char.text}
                                                    </div>
                                                </Radio>
                                                : 
                                                <Radio value={char.text} key={char.text} style={RadioStyle}>{char.text}</Radio>
                                                }
                                            </Box>
                                        </Stack>
                                    </WrapItem>
                                    ))
                                    }
                                    {params.multipleAnswer.other ? 
                                    <Stack>
                                        <Radio value="Others" mb={1}>Lainnya</Radio>
                                        {enableRadioField ? 
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            onChange={(event) => RadioChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        :
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            disabled
                                            onChange={(event) => RadioChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        }
                                    </Stack>
                                    : null}
                                </Wrap>
                            </RadioGroup>
                            : params.predefinedFieldType && params.predefinedFieldType.includes("file") ?
                            <>
                            <WrapItem>
                                <Box mb={4}>
                                    {FileUpload.file !== null && !isImageModalOpen ? 
                                        <div
                                            style={{
                                                position: 'relative',
                                                padding: '8px',
                                                width: 'fit-content',
                                                height: 'fit-content',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.2)',
                                                borderRadius: '4px',
                                                marginTop: '8px',
                                            }}
                                        >
                                            <IconButton
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => deleteFile()}
                                            icon={<CloseIcon />}
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '0',
                                            }}
                                            />
                                            {FileUpload.file.type.includes("image") ? 
                                            <Image
                                                boxSize="256px"
                                                objectFit="cover"
                                                src={URL.createObjectURL(FileUpload.file)}
                                                alt={FileUpload.file.name}
                                                style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24px' }}
                                            />
                                            : FileUpload.file.type.includes("pdf") ?
                                            <>
                                            <WrapItem style={{ margin: '18px' }}>
                                                <HStack>
                                                    <Image
                                                        boxSize="32px"
                                                        objectFit="cover"
                                                        src={pdfImage}
                                                        alt={FileUpload.file.name}
                                                        style={{ marginRight: 'auto' }}
                                                    />
                                                    <Text fontSize="md" ml={2} mr={4} color="#294ABE">
                                                        {FileUpload.file.name}
                                                    </Text>
                                                </HStack> 
                                            </WrapItem> 
                                            </>
                                            : 
                                            <WrapItem style={{ margin: '18px' }}>
                                                <HStack>
                                                    <Icon as={QuestionOutlineIcon}/>
                                                    <Text fontSize="md" ml={2} mr={6} color="#294ABE">
                                                        {FileUpload.file.name}
                                                    </Text>
                                                </HStack> 
                                            </WrapItem> 
                                            }
                                        </div>
                                    : 
                                        <>
                                            <Button id={params.slugField} name={params.slugField} variant="outline" colorScheme="blue" onClick={() => imageUpload(index, {slugField: params.slugField})}>
                                                <AttachmentIcon style={{marginRight: "0.5em", fontSize: "1.5em"}}/>
                                                Upload File
                                            </Button>
                                            {isMobile ? null :
                                            <label
                                            style={{
                                                position: "relative",
                                                padding: "12px",
                                                borderRadius: "8px",
                                                color: "#294ABE",
                                                fontSize: "1em",
                                                display: "inline-block",
                                            }}
                                            >
                                                "Size Limit 4 MB"
                                            </label>
                                            }
                                        </>
                                    }
                                </Box>
                            </WrapItem>
                            {isMobile ? 
                            <label
                            style={{
                                position: "relative",
                                color: "#294ABE",
                                fontSize: "1em",
                                display: "inline-block",
                            }}
                            >
                                {FileUpload.file !== null ? "File Uploaded!" : "Size Limit 4 MB"}
                            </label>
                            : null }
                            </> 
                            : params.predefinedFieldType && params.predefinedFieldType === 'dropdown' ?
                            <Autocomplete
                                options={params.multipleAnswer.choice}
                                style={{ width: 200 }}
                                id="custom-input-demo"
                                getOptionLabel={(option) => option.text}
                                onChange={(_, values) =>
                                dropdownChangeHandler({index, fieldName: params.slugField, value: values.text})
                                }
                                renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <InputGroup size="md">
                                    <Input
                                        placeholder="Choose Answer"
                                        {...params.inputProps}
                                    />
                                    <InputRightElement>
                                        <ChevronDownIcon />
                                    </InputRightElement>
                                    </InputGroup>
                                </div>
                                )}
                            />
                            : params.field_type.displayField === 'Multiple Choice' ?
                            <RadioGroup id={params.slugField} name={params.slugField} type={params.field_type.displayField} onChange={(event) => RadioChangeHandler(index, event, params.slugField, params.field_type.displayField)}>
                                <Wrap 
                                spacing={params.multipleAnswer.choice.map(char => (char.image ? "3em" : "1em"))} 
                                direction={params.multipleAnswer.choice.map(char => (char.image ? "row" : "column"))}
                                >
                                    {
                                    params.multipleAnswer.choice.map(char => (
                                    <WrapItem>
                                        <Stack>
                                            <Box>
                                                {char.image ?  
                                                <Radio value={char.text} key={char.text}>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            padding: "16px",
                                                            maxHeight: "fit-content",
                                                            width: "256px",
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                            marginBottom: "16px",
                                                        }}
                                                    >
                                                        <Image src={char.image} alt={char.image} mb={3} boxSize="100%"/>
                                                        {char.text}
                                                    </div>
                                                </Radio>
                                                : 
                                                <Radio value={char.text} key={char.text} style={RadioStyle}>{char.text}</Radio>
                                                }
                                            </Box>
                                        </Stack>
                                    </WrapItem>
                                    ))
                                    }
                                    {params.multipleAnswer.other ? 
                                    <Stack>
                                        <Radio value="Others" mb={1}>Lainnya</Radio>
                                        {enableRadioField ? 
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            onChange={(event) => RadioChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        :
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            disabled
                                            onChange={(event) => RadioChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        }
                                    </Stack>
                                    : null}
                                </Wrap>
                            </RadioGroup>
                            : params.field_type.displayField === 'File' || params.field_type.displayField.includes("File") ?
                            <>
                            <WrapItem>
                                <Box mb={4}>
                                <Button id={params.slugField} variant="outline" colorScheme="blue" name={params.slugField} onClick={() => imageUpload(index, {slugField: params.slugField}, params.slugField)}>
                                        <AttachmentIcon style={{marginRight: "0.5em", fontSize: "1.5em"}}/>
                                        Upload File
                                    </Button>
                                    {
                                        FileUpload.length && Object.values(FileUpload[index])[0] !== null ?
                                            Object.values(FileUpload[index])[0]?.file.type.includes("image") ? 
                                                <WrapItem style={{ margin: '18px' }}>
                                                    <HStack>
                                                        <Image
                                                            boxSize="256px"
                                                            objectFit="cover"
                                                            src={URL.createObjectURL((Object.values(FileUpload[index])[0]?.file))}
                                                            alt={(Object.values(FileUpload[index])[0]?.variable_name)}
                                                            style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24px' }}
                                                        />
                                                    </HStack> 
                                                </WrapItem>
                                            :
                                            <WrapItem style={{ margin: '18px' }}>
                                                <HStack>
                                                    <Image
                                                        boxSize="32px"
                                                        objectFit="cover"
                                                        src={pdfImage}
                                                        alt={Object.values(FileUpload[index])[0]?.file.name}
                                                        style={{ marginRight: 'auto' }}
                                                    />
                                                    <Text fontSize="md" ml={2} mr={4} color="#294ABE">
                                                        {Object.values(FileUpload[index])[0]?.file.name}
                                                    </Text>
                                                </HStack> 
                                            </WrapItem>
                                            : null
                                        }
    
                                        {isMobile ? null :
                                            <label
                                                style={{
                                                    position: "relative",
                                                    padding: "12px",
                                                    borderRadius: "8px",
                                                    color: "#294ABE",
                                                    fontSize: "1em",
                                                    display: "inline-block",
                                                }}
                                            >
                                                Size Limit 4 MB
                                            </label>
                                        }
                                </Box>
                            </WrapItem>
                            </>
                            : params.predefinedFieldType && params.predefinedFieldType === 'File' ?
                            <>
                            <WrapItem>
                                <Box mb={4}>
                                    {FileUpload.file !== null && !isImageModalOpen ? 
                                        <div
                                            style={{
                                                position: 'relative',
                                                padding: '8px',
                                                width: 'fit-content',
                                                height: 'fit-content',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.2)',
                                                borderRadius: '4px',
                                                marginTop: '8px',
                                            }}
                                        >
                                            <IconButton
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => deleteFile()}
                                            icon={<CloseIcon />}
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '0',
                                            }}
                                            />
                                            {FileUpload.file.type.includes("image") ? 
                                            <Image
                                                boxSize="256px"
                                                objectFit="cover"
                                                src={URL.createObjectURL(FileUpload.file)}
                                                alt={FileUpload.file.name}
                                                style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24px' }}
                                            />
                                            : FileUpload.file.type.includes("pdf") ?
                                            <>
                                            <WrapItem style={{ margin: '18px' }}>
                                                <HStack>
                                                    <Image
                                                        boxSize="32px"
                                                        objectFit="cover"
                                                        src={pdfImage}
                                                        alt={FileUpload.file.name}
                                                        style={{ marginRight: 'auto' }}
                                                    />
                                                    <Text fontSize="md" ml={2} mr={4} color="#294ABE">
                                                        {FileUpload.file.name}
                                                    </Text>
                                                </HStack> 
                                            </WrapItem> 
                                            </>
                                            : 
                                            <WrapItem style={{ margin: '18px' }}>
                                                <HStack>
                                                    <Icon as={QuestionOutlineIcon}/>
                                                    <Text fontSize="md" ml={2} mr={6} color="#294ABE">
                                                        {FileUpload.file.name}
                                                    </Text>
                                                </HStack> 
                                            </WrapItem> 
                                            }
                                        </div>
                                    : 
                                        <>
                                            <Button id={params.slugField} variant="outline" colorScheme="blue" name={params.slugField} onClick={() => imageUpload(index, {slugField: params.slugField})}>
                                                <AttachmentIcon style={{marginRight: "0.5em", fontSize: "1.5em"}}/>
                                                Upload File
                                            </Button>
                                            {isMobile ? null :
                                            <label
                                            style={{
                                                position: "relative",
                                                padding: "12px",
                                                borderRadius: "8px",
                                                color: "#294ABE",
                                                fontSize: "1em",
                                                display: "inline-block",
                                            }}
                                            >
                                                Size Limit 4 MB
                                            </label>
                                            }
                                        </>
                                    }
                                </Box>
                            </WrapItem>
                            {isMobile ? 
                            <label
                            style={{
                                position: "relative",
                                color: "#294ABE",
                                fontSize: "1em",
                                display: "inline-block",
                            }}
                            >
                                {FileUpload.file !== null ? "File Uploaded!" : "Size Limit 4 MB"}
                            </label>
                            : null }
                            </>
                            : params.field_type.displayField === "Dropdown" ?
                            <Autocomplete
                                options={params.multipleAnswer.choice}
                                style={{ width: 200 }}
                                id="custom-input-demo"
                                getOptionLabel={(option) => option.text}
                                onChange={(_, values) =>
                                dropdownChangeHandler({index, fieldName: params.slugField, value: values.text})
                                }
                                renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <InputGroup size="md">
                                        <Input
                                            placeholder="Choose Answer"
                                            {...params.inputProps}
                                        />
                                        <InputRightElement>
                                            <ChevronDownIcon />
                                        </InputRightElement>
                                    </InputGroup>
                                </div>
                                )}
                            />
                            : params.field_type.displayField === 'Checkbox' ?
                            <CheckboxGroup id={params.slugField} colorScheme="blue" name={params.slugField} type={params.field_type.displayField} onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, params.field_type.displayField)}>
                                <Wrap 
                                spacing={params.multipleAnswer.choice.map(char => (char.image ? "3em" : "1em"))} 
                                direction={params.multipleAnswer.choice.map(char => (char.image ? "row" : "column"))}
                                mb={4}
                                >
                                    {
                                    params.multipleAnswer.choice.map(char => (
                                    <WrapItem>
                                        <Stack>
                                            <Box>
                                                {char.image ?  
                                                <Checkbox value={char.text}>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            padding: "16px",
                                                            maxHeight: "fit-content",
                                                            width: "256px",
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                            marginBottom: "16px",
                                                        }}
                                                    >
                                                        <Image src={char.image} alt={char.image} mb={3} boxSize="100%"/>
                                                        {char.text}
                                                    </div>
                                                </Checkbox>
                                                : 
                                                <Checkbox value={char.text}>{char.text}</Checkbox>
                                                }
                                                
                                            </Box>
                                        </Stack>
                                    </WrapItem>
                                    ))
                                    }
                                    {params.multipleAnswer.other ? 
                                    <Stack>
                                        <Checkbox value="Others" mb={1}>Lainnya</Checkbox>
                                        {enableCheckboxField ? 
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text" 
                                            onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        :
                                        <Input 
                                            className="input" 
                                            id={params.slugField} 
                                            name={params.slugField} 
                                            type="text"
                                            disabled 
                                            onChange={(event) => CheckboxChangeHandler(index, event, params.slugField, "input")}
                                            style={{
                                                position: "relative",
                                                padding: "8px",
                                                width: "256px",
                                                marginLeft: "24px",
                                                marginRight: "auto",
                                                marginBottom: "16px",
                                            }}
                                        />
                                        }
                                    </Stack>
                                    : null}
                                </Wrap>
                            </CheckboxGroup>
                            : params.field_type.displayField === 'Linear Scale' ?
                            <RadioGroup mt={5} id={params.slugField} name={params.slugField} type={params.field_type.displayField} onChange={(event) => RadioChangeHandler(index, event, params.slugField, params.field_type.displayField)}>
                                <Stack mt={5} direction="row">
                                    {params.multipleAnswer.linear_array.map(linearValue => (
                                        <>
                                            <Text fontSize="md">{linearValue.nilai}</Text>
                                            {linearValue === params.multipleAnswer.linear_array.slice(-1)[0] ? null : <Spacer/>}
                                        </>
                                    ))}
                                </Stack>
                                <Stack mb={3} direction="row">
                                    {params.multipleAnswer.linear_array.map(linearValue => (
                                        <>
                                            <Stack mb={3} mt={3} direction="row">
                                                <Radio 
                                                    value={linearValue.nilai} 
                                                    key={linearValue.nilai}
                                                    style={RadioStyle}
                                                ></Radio>
                                            </Stack>
                                            {linearValue === params.multipleAnswer.linear_array.slice(-1)[0] ? null : <Spacer/>}
                                        </>
                                    ))}
                                </Stack>
                                <Stack direction="row">
                                    <Box style={{ width: "100px" }}>
                                        <Text fontSize="md" align="left" justifyContent="flex-start">{params.multipleAnswer.multipleAnswer.min_scale_label}</Text>
                                    </Box>
                                    <Spacer/>
                                    <Box style={{ width: "100px" }}>
                                        <Text fontSize="md" align="right" justifyContent="flex-end">{params.multipleAnswer.multipleAnswer.max_scale_label}</Text>
                                    </Box>
                                </Stack>
                            </RadioGroup>
                            : params.predefinedFieldType && params.predefinedFieldType === 'linearscale' ?
                            <RadioGroup mt={5} id={params.slugField} name={params.slugField} type={params.field_type.displayField} onChange={(event) => RadioChangeHandler(index, event, params.slugField, params.field_type.displayField)}>
                                <Stack mt={5} direction="row">
                                    {params.multipleAnswer.linear_array.map(linearValue => (
                                        <>
                                            <Text fontSize="md">{linearValue.nilai}</Text>
                                            {linearValue === params.multipleAnswer.linear_array.slice(-1)[0] ? null : <Spacer/>}
                                        </>
                                    ))}
                                </Stack>
                                <Stack mb={3} direction="row">
                                    {params.multipleAnswer.linear_array.map(linearValue => (
                                        <>
                                            <Stack mb={3} mt={3} direction="row">
                                                <Radio value={linearValue.nilai} key={linearValue.nilai} style={RadioStyle}></Radio>
                                            </Stack>
                                            {linearValue === params.multipleAnswer.linear_array.slice(-1)[0] ? null : <Spacer/>}
                                        </>
                                    ))}
                                </Stack>
                                <Stack direction="row">
                                    <Box style={{ width: "100px" }}>
                                        <Text fontSize="md" align="left" justifyContent="flex-start">{params.multipleAnswer.multipleAnswer.min_scale_label}</Text>
                                    </Box>
                                    <Spacer/>
                                    <Box style={{ width: "100px" }}>
                                        <Text fontSize="md" align="right" justifyContent="flex-end">{params.multipleAnswer.multipleAnswer.max_scale_label}</Text>
                                    </Box>
                                </Stack>
                            </RadioGroup>
                            : params.field_type.displayField === 'Multi Choice Grid' ?
                            <div style={{overflowX: 'auto', position: 'relative'}}>
                                <Box key={params.id} style={params.multipleAnswer.column_length > 4 && isMobile ? {overflowX: 'auto', minWidth: '175%'} : null}>
                                    <Flex direction="row">
                                        <Box mb={3} mt={3} w={params.multipleAnswer.column_length < 4 ? '50%' : '25%'}>
                                            {/* Nothing */}
                                        </Box>
                                        <Stack
                                            align="center"
                                            justifyContent="flex-center"
                                            mb={3}
                                            mt={3}
                                            w={params.multipleAnswer.column_length < 4 ? '50%' : '75%'}
                                            direction="row"
                                        >
                                            {params.multipleAnswer.column.map(dataKolom => (
                                                <>
                                                    <Box textAlign="left" justifyContent="flex-end">
                                                        <Text textAlign="left" fontSize="md">{dataKolom.nilai}</Text>
                                                    </Box>
                                                    {dataKolom === params.multipleAnswer.column.slice(-1)[0] ? null : <Spacer/>}
                                                </>
                                            ))}
                                        </Stack>
                                    </Flex>
                                    <Stack>
                                        {params.multipleAnswer.row.map((dataRow, indexRow) => (
                                            <RadioGroup
                                                mt={5}
                                                id={params.slugField}
                                                name={params.slugField}
                                                rowName={dataRow}
                                                rowIndex={indexRow}
                                                type={params.inputType}
                                                onChange={(event) => MultiChoiceGrid(index, event, params.slugField, dataRow, indexRow)}
                                            >
                                                <Flex mb={3} direction="row" spacing='2em'>
                                                    <Box mb={3} mt={3} mr={3} w={params.multipleAnswer.column_length < 4 ? '50%' : '25%'}>
                                                        <Text fontSize="md">{dataRow}</Text>
                                                    </Box>
                                                    <Spacer/>
                                                    <Stack
                                                        mb={3}
                                                        mt={3}
                                                        w={params.multipleAnswer.column_length < 4 ? '50%' : '75%'}
                                                        direction="row"
                                                    >
                                                        {params.multipleAnswer.column.map(dataKolom => (
                                                            <>
                                                                <Stack>
                                                                    <Radio
                                                                        value={dataKolom.nilai.toString()}
                                                                        key={dataKolom.nilai}
                                                                        style={RadioStyle}
                                                                    ></Radio>
                                                                </Stack>
                                                                {dataKolom === params.multipleAnswer.column.slice(-1)[0] ? null : <Spacer/>}
                                                            </>
                                                        ))}
                                                    </Stack>
                                                </Flex>
                                            </RadioGroup>
                                        ))}
                                    </Stack>
                                </Box>
                            </div>
                            : params.field_type.displayField === 'Check Box Grid' ?
                            <div style={{overflowX: 'auto', position: 'relative'}}>
                                <Box key={params.id} style={params.multipleAnswer.column_length > 4 && isMobile ? {overflowX: 'auto', minWidth: '175%'} : null}>
                                    <Flex direction="row">
                                        <Box mb={3} mt={3} w={params.multipleAnswer.column_length < 4 ? '50%' : '25%'}>
                                            {/* Nothing */}
                                        </Box>
                                        <Stack
                                            align="center"
                                            justifyContent="flex-center"
                                            mb={3}
                                            mt={3}
                                            w={params.multipleAnswer.column_length < 4 ? '50%' : '75%'}
                                            direction="row"
                                        >
                                            {params.multipleAnswer.column.map(dataKolom => (
                                                <>
                                                    <Box textAlign="left" justifyContent="flex-end">
                                                        <Text textAlign="left" fontSize="md">{dataKolom.nilai}</Text>
                                                    </Box>
                                                    {dataKolom === params.multipleAnswer.column.slice(-1)[0] ? null : <Spacer/>}
                                                </>
                                            ))}
                                        </Stack>
                                    </Flex>
                                    <Stack>
                                        {params.multipleAnswer.row.map((dataRow, indexRow) => (
                                            <Flex mt={5} direction="row" spacing='2em'>
                                                <Box mb={3} mt={3} mr={3} w={params.multipleAnswer.column_length < 4 ? '50%' : '25%'}>
                                                    <Text fontSize="md">{dataRow}</Text>
                                                </Box>
                                                <Spacer/>
                                                <Stack
                                                    mb={3}
                                                    mt={3}
                                                    w={params.multipleAnswer.column_length < 4 ? '50%' : '75%'}
                                                    direction="row"
                                                >
                                                    {params.multipleAnswer.column.map(dataKolom => (
                                                        <>
                                                            <Stack>
                                                                <Checkbox
                                                                    style={RadioStyle}
                                                                    id={params.slugField}
                                                                    name={params.slugField}
                                                                    rowName={dataRow}
                                                                    rowIndex={indexRow}
                                                                    type={params.inputType}
                                                                    onChange={(event) => MultiCheckboxGrid(index, event, params.slugField, dataRow, indexRow)}
                                                                    value={dataKolom.nilai.toString()}
                                                                    key={dataKolom.nilai}
                                                                ></Checkbox>
                                                            </Stack>
                                                            {dataKolom === params.multipleAnswer.column.slice(-1)[0] ? null : <Spacer/>}
                                                        </>
                                                    ))}
                                                </Stack>
                                            </Flex>
                                        ))}
                                    </Stack>
                                </Box>
                            </div>
                            :
                            <Input 
                                className="input" 
                                id={params.slugField} 
                                name={params.slugField} 
                                type="text"
                                onChange={(event) => myChangeHandler(index, event)}
                            />
                            }
                            {FieldsbyForms[index] && emptyFieldsWarning[index] ? 
                                emptyFieldsWarning[index].field === FieldsbyForms[emptyFieldsWarning[index].fieldIndex ? emptyFieldsWarning[index].fieldIndex : 0].title ? 
                                    <label
                                    style={{
                                        position: "relative",
                                        color: "red",
                                        fontSize: "1.1em",
                                        display: "inline-block",
                                        marginTop: "1em"
                                    }}
                                    >
                                        Mohon lengkapi isian ini.
                                    </label>
                                : null 
                            : null }
                        </Box>
                        {
                            params.indexField === FieldsbyForms.length ? null :
                            <hr className="solid" style={{borderTop: `2px solid ${FormList[0].bgHexColor || '#D1D9F5'}`}}/>
                        }
                    </>
                    ) : null }
                    </Box>
                    {FormList[0].isConsentActive ?
                    <Box width={{ base: "90%", md: "90%", lg: "50%"}} mb={6} background="white" borderWidth="1px" borderRadius="lg">
                        <Box padding="24px 24px 24px 24px" background="white" align={isMobile ? "center" : "flex-start"} borderRadius="lg">
                            <Checkbox name="privacyPolicy" onChange={privacyPolicyHandler} value={!privacyPolicy}>
                                <div style={{ position: "relative", marginLeft: "4px", marginTop: "4px" }}>
                                    <Text>
                                        I have read and agree the&nbsp;
                                        <a
                                            href={FormList[0] ? FormList[0].privacyPolicyLink : null}
                                            style={{ color: "#0056CC" }}
                                            target="_blank"
                                        >privacy policy</a>
                                    </Text>
                                </div>
                            </Checkbox>
                        </Box>
                    </Box>
                    :
                    null
                    }
                    <Box width={{ base: "90%", md: "90%", lg: "50%"}} align={isMobile ? "center" : "right"} justifyContent={isMobile ? "flex-center" : "flex-end"} borderRadius="lg">
                        {FormList[0].isConsentActive ? 
                            privacyPolicy ? (
                                isSubmitting ? 
                                    <Button
                                        colorScheme="blue"
                                        backgroundColor="#294ABE"
                                        isLoading
                                        loadingText='Submitting Results...'
                                        style={isMobile ? { width: "100%" } : null}
                                    >
                                        Submit
                                    </Button>
                                    :
                                    <Button
                                        colorScheme="blue"
                                        backgroundColor="#294ABE"
                                        onClick={handleSubmit}
                                        style={isMobile ? { width: "100%" } : null}
                                    >
                                        Submit
                                    </Button>
                                )
                            : (
                                <Button
                                    colorScheme="blue"
                                    backgroundColor="#294ABE"
                                    disabled
                                    style={isMobile ? { width: "100%" } : null}
                                >
                                    Submit
                                </Button>
                            )
                        :   (
                            isSubmitting ? 
                                <Button
                                    colorScheme="blue"
                                    backgroundColor="#294ABE"
                                    isLoading
                                    loadingText='Submitting Results...'
                                    style={isMobile ? { width: "100%" } : null}
                                >
                                    Submit
                                </Button>
                                :
                                <Button
                                    colorScheme="blue"
                                    backgroundColor="#294ABE"
                                    onClick={handleSubmit}
                                    style={isMobile ? { width: "100%" } : null}
                                >
                                    Submit
                                </Button>
                            )
                        }
                    </Box>
                    <Modal isOpen={isImageModalOpen} size="lg" onClose={() => closeImageModal()}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>File Upload</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Input 
                                    className="input" 
                                    id={selectedFieldSlugField} 
                                    name={selectedFieldSlugField} 
                                    type="file" 
                                    onChange={(event) => FileChangeHandler(selectedFieldIndex, event, selectedFieldSlugField)}
                                    style={{
                                        position: "relative",
                                        paddingTop: "72px",
                                        paddingBottom: "72px",
                                        height: "auto",
                                        borderRadius: "8px",
                                        marginBottom: "12px"
                                    }}
                                />
                                <WrapItem>
                                    <p
                                        style={{display: "block", color: "black", marginBottom: "12px"}}>
                                        Drag & Drop atau Klik untuk mengunggah berkas. Mendukung format file PDF, JPG, JPEG dan PNG.
                                    </p>
                                </WrapItem>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" onClick={() => submitFile(selectedFieldIndex, selectedFieldSlugField)}>
                                    Submit File
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box>    
            : 
            <Box pb={6} className="form-body">
                <Box mb={6} mt={6} width={{ base: "90%", md: "90%", lg: "50%"}} padding="32px 24px 24px 24px" background="white" borderWidth="1px" borderRadius="lg">
                    <Heading as="h1" size="md" mb={2}>
                        {FormIsInactive.message}
                    </Heading>
                </Box>
            </Box>
            }
        </Box>
        : 
        null 
        }
        </>
    );
}

export default FormPage;
