import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Form from './Form/FormPage';
import ThankYou from './Form/ThankYou';
import NotFound from './Home/NotFound';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Tooltip: {
      variants: {
        "white": {
          bg: "white",
          color: "black",
          padding: "16px",
          borderRadius: "4px",
        }
      },
    },
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/thankyou/:id">
            <ThankYou/>
          </Route>
          <Route path="/:id">
            <Form/>
          </Route>
          <Route path="/">
            <NotFound/>
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
